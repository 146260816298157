class LoginModule extends HTMLElement {
  constructor () {
    super ()
    const dataManageSubscriptions =  this.dataset.manageSubscriptions

    if (dataManageSubscriptions) {
      window.rcLoginAccessLink = {
        translation: {
          en: {
            linkText: dataManageSubscriptions,
          }
        }
      }
    }
  }
}

window.customElements.define('login-module', LoginModule)
